<script>
  import RawContent from '@/shared/ui/RawContent.vue';

  import DetailTopBar from '@/features/DetailTopBar.vue';

  export default {
    name: 'SpellBody',
    components: {
      RawContent,
      DetailTopBar,
    },
    props: {
      spell: {
        type: Object,
        default: undefined,
        required: true,
      },
      inTooltip: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      tooltip: {
        v: 'Більшість заклинань вимагають вимови таємничих слів. Самі по собі слова не є джерелом сили заклинання; просто комбінація звуків з особливою тональністю викликає резонанс у пасмах магії, наводячи їх у рух. Таким чином, персонаж з кляпом у роті або в області заклинання тиша не може активувати заклинання з вербальним компонентом.',
        s: 'Заклинання може вимагати енергійної жестикуляції або хитромудрої послідовності рухів тіла.\nЯкщо у заклинання є соматичний компонент, у заклинача повинна бути вільною хоча б одна рука для виконання цих жестів.',
        m: 'У заклинача має бути одна вільна рука для доступу до матеріальних компонентів, але це може бути та сама рука, що використовується для виконання соматичного компонента.\nПерсонаж може використовувати мішечок з компонентами або фокусування заклинання замість зазначених компонентів. Однак, якщо для компонента вказана ціна, у персонажа для накладання заклинання має бути саме такий компонент.',
      },
    }),
  };
</script>

<template>
  <div
    v-if="spell"
    :class="{ 'in-tooltip': inTooltip }"
    class="spell_wrapper spell-body"
  >
    <detail-top-bar
      :bg-grey="false"
      :left="`${spell.level ? `${spell.level} уровень` : 'заговор'}, ${
        spell.school
      }${spell.additionalType ? ` [${spell.additionalType}]` : ''}${
        spell.ritual ? ' (ритуал)' : ''
      }`"
      :source="spell.source"
    />

    <div class="grid_stat_block">
      <div class="block">
        <p>Час накладання:</p>

        <span>{{ spell.time }}</span>
      </div>

      <div class="block distance">
        <p>Дистанція:</p>

        <span>{{ spell.range }}</span>
      </div>

      <div class="block duration">
        <p>Тривалісь:</p>

        <span>{{ spell.duration }}</span>
      </div>

      <div class="block one_row">
        <p>Компоненти:</p>

        <span
          v-if="spell.components.v"
          v-tippy="{ content: tooltip.v }"
          >Вербальний{{
            spell.components.s || spell.components.m ? ', ' : ''
          }}</span
        >

        <span
          v-if="spell.components.s"
          v-tippy="{ content: tooltip.s }"
          >Соматичний{{ spell.components.m ? ', ' : '' }}</span
        >

        <span
          v-if="spell.components.m"
          v-tippy="{ content: tooltip.m }"
          >Матеріальний ({{ spell.components.m }})</span
        >
      </div>
    </div>

    <div class="content-padding">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <raw-content :template="spell.description" />

      <p v-if="spell.upper">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <strong>На вищих рівнях: </strong>

        <raw-content
          :template="spell.upper"
          tag="span"
        />
      </p>

      <div
        v-if="spell.classes?.length"
        class="spell_stat_block_bottom"
      >
        <p>Класи:</p>

        <div class="classes_icon">
          <span
            v-for="(el, key) in spell.classes"
            :key="key"
          >
            <router-link :to="{ path: el.url }">{{ el.name }}</router-link>

            <span v-if="key !== spell.classes.length - 1">,&nbsp;</span>
          </span>
        </div>
      </div>

      <div
        v-if="spell.subclasses?.length"
        class="spell_stat_block_bottom"
      >
        <p>Підкласи:</p>

        <div>
          <span
            v-for="(el, key) in spell.subclasses"
            :key="key"
          >
            <router-link
              v-tippy="{ content: el.class }"
              :to="{ path: el.url }"
              >{{ el.name }}</router-link
            >

            <span v-if="key !== spell.subclasses.length - 1">,&nbsp;</span>
          </span>
        </div>
      </div>

      <div
        v-if="spell.races?.length"
        class="spell_stat_block_bottom"
      >
        <p>Раси та походження:</p>

        <div>
          <span
            v-for="(el, key) in spell.races"
            :key="key"
          >
            <router-link :to="{ path: el.url }">{{ el.name }}</router-link>

            <span v-if="key !== spell.races.length - 1">, </span>
          </span>
        </div>
      </div>

      <div
        v-if="spell.backgrounds?.length"
        class="spell_stat_block_bottom"
      >
        <p>Передісторії:</p>

        <div>
          <span
            v-for="(el, key) in spell.backgrounds"
            :key="key"
          >
            <router-link :to="{ path: el.url }">{{ el.name }}</router-link>

            <span v-if="key !== spell.backgrounds.length - 1">,&nbsp;</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
