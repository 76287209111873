import * as Sentry from '@sentry/vue';
import 'virtual:svg-icons-register';
import 'vite/modulepreload-polyfill';
import { createApp } from 'vue';
import { createVfm } from 'vue-final-modal';
import VueLazyLoad from 'vue-lazyload';
import VueTippy from 'vue-tippy';
import Toast from 'vue-toastification';

import { httpClient } from '@/shared/api';
import { TippyLazy } from '@/shared/directives/TippyLazy';
import isDev from '@/shared/utils/isDev';

import App from './App.vue';
import { TippyOptions } from './configs/TippyConfig';
import { ToastEventBus, ToastOptions } from './configs/ToastConfig';
import router from './router';
import pinia from './store';
import registerComponents from './utils/RegisterComponents';

import 'vue-final-modal/style.css';
import '@/assets/styles/index.scss';
import type { ComponentPublicInstance } from 'vue';

const app = createApp(App);
const vfm = createVfm();

app.config.globalProperties.$http = httpClient;

Sentry.init({
  app,
  dsn: 'https://cd072ee984324d138d658fab08f7d479@errors.d20.net.ua/1',
  replaysSessionSampleRate: 1,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
});

const existingHandler = app.config.errorHandler;

app.config.errorHandler = (
  err: unknown,
  instance: ComponentPublicInstance | null,
  info: string,
) => {
  if (existingHandler) {
    existingHandler(err, instance, info);
  }

  Sentry.captureException(err);
};

window.addEventListener('unhandledrejection', (event) => {
  Sentry.captureException(event.reason);
});

app
  .use(pinia)
  .use(router)
  .use(VueTippy, TippyOptions)
  .use(VueLazyLoad)
  .use(Toast, {
    ...ToastOptions,
    eventBus: ToastEventBus,
  })
  .use(vfm)
  .directive('tippy-lazy', TippyLazy);

if (!isDev) {
  // app.use(VueGtag, { config: { id: import.meta.env.VITE_GTAG_ID } });
}

registerComponents(app);

app.mount('#dnd5club');
