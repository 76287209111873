import * as Sentry from '@sentry/vue';

import isDev from '@/shared/utils/isDev';

export const errorHandler = (err: any) => {
  if (isDev) {
    console.error(err);
  }

  Sentry.captureException(err);
};
