<script>
  import RawContent from '@/shared/ui/RawContent.vue';

  import DetailTopBar from '@/features/DetailTopBar.vue';

  export default {
    name: 'OptionBody',
    components: {
      DetailTopBar,
      RawContent,
    },
    props: {
      option: {
        type: Object,
        default: undefined,
        required: true,
      },
    },
  };
</script>

<template>
  <div class="option-body">
    <detail-top-bar
      :left="`Вимоги: ${option?.requirements || 'Немає'}`"
      :source="option?.source"
    />

    <div class="option-body__desc content-padding">
      <raw-content :template="option?.description" />

      <div
        v-if="option?.classes?.length"
        class="spell_stat_block_bottom"
      >
        <p>Класи:</p>

        <div class="classes_icon">
          <span
            v-for="(el, key) in option.classes"
            :key="key"
          >
            <router-link :to="{ path: el.url }">{{ el.name }}</router-link>

            <span v-if="key !== option.classes.length - 1">,&nbsp;</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
